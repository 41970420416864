<template>
    <responsive-ui-list
    :useDecrementalScaling="true"
    :disableXScroll="true"
    :heading="heading"
    :headings="[
        $t('fields.name'),
        $t('fields.next_group'),
        $t('fields.instructor'),
        $t('models.finished_groups'),
        $tc('misc.courseEvents.status', 2),
        'search'
    ]"
    :sort="[
      { prop: 'title', type: false },
      undefined,
      undefined,
      { prop: 'progress', prop2: 'progressPercentage', type: 'level2' },
    ]"
    :items="courseEvents"
    :search_selectors="['title']"
    >
        <template v-if="$slots.actions" v-slot:actions>
            <slot name="actions"></slot>
        </template>
        <template v-slot:default="props">
            <ui-list-data :text="props.item.title"></ui-list-data>
            <ui-list-data class="whitespace-no-wrap" :text="nextGroupTimeFormatted(props.item)"></ui-list-data>
            <ui-list-data type="tag" :text="`${props.item.instructorModel.firstName} ${props.item.instructorModel.lastName}`"></ui-list-data>
            <template>
                <ui-list-data-progress
                v-if="typeof getPercentage(props.item) === 'number'"
                :percentage="getPercentage(props.item)"
                :progressDetails="props.item.progress"
                >
                </ui-list-data-progress>
                <ui-list-data-progress
                v-else
                :percentage="0"
                :progressDetails="{ assignedCount: 0, completedCount: 0}"
                >
                </ui-list-data-progress>
            </template>
            <template>
                <ui-list-data small v-if="props.item.active === true">
                    {{ $tc('misc.courseEvents.active', 1) }}
                </ui-list-data>
                <ui-list-data small v-if="props.item.active === false">
                    {{ $tc('misc.courseEvents.inactive', 1) }}
                </ui-list-data>
            </template>

            <ui-list-data small>
                <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
                    <ui-button
                        type="green"
                        size="small"
                        uppercase
                        @click="
                        $router.push({
                            name: 'courseevent-view',
                            params: { id: props.item.id }
                        })
                        "
                        >{{ $t('actions.show_content') }}</ui-button
                    >
                </auth-checker>
                <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin']">
                    <ui-button
                        class="ml-2"
                        type="grey"
                        size="small"
                        uppercase
                        @click="
                        $router.push({
                            name: 'courseevent-edit',
                            params: { id: props.item.id }
                        })
                        "
                        >{{ $t('actions.edit') }}</ui-button
                    >
                </auth-checker>
            </ui-list-data>
        </template>
    </responsive-ui-list>
</template>

<script>
import ResponsiveUiList from '../components/ResponsiveUiList';
import UiListData from '@/components/ui/UiListData';
import UiListDataProgress from '@/components/ui/UiListDataProgress';
import UiButton from "@/components/ui/UiButton";
import AuthChecker from '../../auth/components/AuthChecker';
import {capitalized} from "@/extras";

export default {
    props: {
        heading: {
            type: String
        },
        courseEvents: {
            type: Array,
            required: true
        }
    },

    watch: {
      courseEvents(_, __){
        // Create uppercase locations to ensure correct sorting
        this.courseEvents.forEach((courseEvent) => {
          courseEvent.title = capitalized(courseEvent.title);
        });
      }
    },

    components: {
        ResponsiveUiList,
        UiListData,
        UiListDataProgress,
        UiButton,
        AuthChecker
    },

    methods: {
        getPercentage(item) {
            if (item.progress && item.progress.assignedCount) {
                return item.progress.progressPercentage;
            } else {
                if (item.assignedCount) {
                return Math.floor(
                    (item.completedCount / item.assignedCount) * 100
                );
                } else {
                    return false;
                }
            }
        },

        /**
         * Gets upcoming timeslot by course event.
         * @param {Object} courseEvent The course-event to get upcoming time slot for
         * @returns {string}
         */
        nextGroupTimeFormatted(courseEvent) {
            if (courseEvent == null || courseEvent.sessions == null || courseEvent.sessions.length == 0)
                return this.$t("feedback.no_groups");

            const sessions = structuredClone(courseEvent.sessions);
            const sessionsSorted = sessions.sort((a,b) => Date.parse(a.startDateTime) > Date.parse(b.startDateTime));
            const date = sessionsSorted.find(x => Date.parse(x.startDateTime) > Date.now());

            return date == null ? this.$t("feedback.groups_finished") : `${this.$d(new Date(date.startDateTime), 'fullShort')} ${this.$d(new Date(date.startDateTime), 'time')}`;
        }
    }
}
</script>