<template>
  <div>
    <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
      <ui-header :heading="$tc('models.event', 2)">
          <button
            @click="upcomingEventsChecked()"
            class="inline-block font-bold rounded text-sm px-4 py-2 text-gray-500 hover:bg-white hover:text-gray-600"
            :class="upcomingEventsCheckbox ? 'active_checkbox' : 'inactive_checkbox'">
            {{$t('actions.upcoming_events')}}
          </button>
          <button
          @click="inactiveChecked()"
            class="inline-block font-bold rounded text-sm px-4 py-2 text-gray-500 hover:bg-white hover:text-gray-600"
            :class="inactiveCheckbox ? 'active_checkbox' : 'inactive_checkbox'">
            {{`${$t('actions.show')} ${$t('misc.inactive')}`}}
          </button>

        <!-- Create course event requires admin -->
        <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin']">
          <ui-link class="add_new_button" type="text" :route="{ name: 'courseevent-create'}">
            {{ `${$tc('actions.add_new', 1)} ${$t('models.event')}` }}
          </ui-link>
        </auth-checker>
      </ui-header>

      <courseEvent-list :courseEvents="courseEvents" v-loading="loading"></courseevent-list>
    </auth-checker>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import AuthChecker from '../../auth/components/AuthChecker';
import CourseEventList from '../components/CourseEventList';
import { getCourseEvents, getInactiveCourseEvents } from '../api';

export default {
  data() {
    return {
      courseEvents: [],
      loading: false,
      inactiveCheckbox: false,
      upcomingEventsCheckbox: true,
    };
  },

  components: {
    UiHeader,
    UiLink,
    CourseEventList,
    AuthChecker
  },

  methods: {
    async getCourseEvents() {
      this.loading = true;
      try {
        const courseEvents = await getCourseEvents();
        this.courseEvents = courseEvents.sort((a, b) => (a.Title > b.Title ? -1 : 1));
        this.loading = false;
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', {
            msg: this.$t('models.event')
          }),
          type: 'error'
        }
        this.$message(msg);
      }
    },

    async getInactiveCourseEvents() {
      this.loading = true;
      try {
        const courseEvents = await getInactiveCourseEvents();
        this.courseEvents = courseEvents.sort((a, b) => (a.Title > b.Title ? -1 : 1));
        this.loading = false;
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', {
            msg: this.$t('models.event')
          }),
          type: 'error'
        }
        this.$message(msg);
      }
    },

    inactiveChecked() {
      this.inactiveCheckbox = !this.inactiveCheckbox;
      this.getData();
    },

    upcomingEventsChecked() {
      this.upcomingEventsCheckbox = !this.upcomingEventsCheckbox;
      this.getData();
    },

    async getData()
    {
      if (this.inactiveCheckbox === true)
      {
        await this.getInactiveCourseEvents();
        if (this.upcomingEventsCheckbox === true) this.sortByUpComingEvents();
      }
      else
      {
        await this.getCourseEvents();
        if (this.upcomingEventsCheckbox === true) this.sortByUpComingEvents();
      }
    },
    
    sortByUpComingEvents() 
    {
      this.courseEvents = this.courseEvents.sort(function(a, b){
        // Checking zero-length sessions
        if (a.sessions.length === 0 && b.sessions.length === 0)
          return 0;
        if (a.sessions.length === 0)
          return 1;
        if (b.sessions.length === 0)
          return -1;

        // Sort the lists on date so that the find() method finds the first matching result.
        const ListASorted = a.sessions.sort((c,d) => Date.parse(c.startDateTime) > Date.parse(d.startDateTime));
        const ListBSorted = b.sessions.sort((c,d) => Date.parse(c.startDateTime) > Date.parse(d.startDateTime));
        var DateA = ListASorted.find(x => Date.parse(x.startDateTime) > Date.now());
        var DateB = ListBSorted.find(y => Date.parse(y.startDateTime) > Date.now());

        // Checking if no dates were found
        if (DateA === undefined && DateB === undefined)
          return 0;
        if (DateA === undefined)
          return 1;
        if (DateB === undefined)
          return -1;
        
        DateA = Date.parse(DateA.startDateTime);
        DateB = Date.parse(DateB.startDateTime);

        if (DateA > DateB) { return 1 }
        else if (DateA < DateB) { return -1 }
        else { return 0 }
      });
    },
  },

  async created() {
    await this.getData();
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.event", link: "/app/courseevent/"}
    ]);
  },
};
</script>

<style scoped>
.inactive_checkbox {
  background-color: white;
  border: solid 1px black;
  color: black;
  margin: 5px;
}

.active_checkbox {
  background-color: greenyellow;
  border: solid 1px black;
  color: black;
  margin: 5px;
}

.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}
</style>
